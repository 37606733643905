/* eslint-disable */

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { Text, Title } from '~/components'
import Accordion from '~/components/accordion'
import { breakpointMatch } from '~/helpers/breakpoints'
import history from '~/helpers/history'
import queryMatch from '~/helpers/queryMatch'
import MainLayout from '~/layouts/mainLayout'
import { getViewPortSize } from '~/store/reducers/ui/selectors'
import IconSearch from '~/svgs/iconSearch'

import { path as homePath } from '../homePage'
import { FaqWrapper, StyledDarkBox, StyledTextInput } from './styles'

export const title = 'Perguntas frequentes'
export const path = '/perguntas-frequentes'

const faq = [
	{
		question: 'Como faço para entrar em contato com a empresa?',
		answer: `Você pode entrar em contato com a empresa enviando um e-mail para este endereço: atendimento.portal.boas.vindas@americanas.io`,
	},
	{
		question: 'Como saber se os meus dados foram enviados?',
		answer: `A interação do candidato com o Portal será sempre via e-mail.`,
		keywords: '',
	},
	{
		question: 'Como saber se a Empresa entrou em contato comigo?',
		answer: `Todas as interações serão feitas via e-mail cadastrado incialmente no Portal.`,
	},
	{
		question: 'O que é o cadastro no Portal Boas Vindas?',
		answer: `O cadastro no Portal Boas Vindas é uma ação vinculada ao processo seletivo, onde há o registro dos dados pertinentes ao processo admissional na americanas.`,
	},
	{
		question:
			'Os meus dados cadastrados no Portal Boas Vindas estão irregulares e agora? Como proceder?',
		answer: `Siga as orientações disponivéis no Portal ou no seu e-mail.`,
	},
	{
		question: 'Para qual vaga estou concorrendo?',
		answer: `O perfil da vaga foi descrita na plataforma onde foi feita a inscrição inicial no processo de recrutamento e seleção.`,
	},
	{
		question: 'Ainda não tenho certificado de reservista, como proseguir no cadastro?',
		answer: `O certificado de reservista é obrigario para todo cidadão brasileiro do sexo masculino e maior de 18 anos.`,
	},
	{
		question: 'Porque não consigo incluir os dados bancários?',
		answer: `O campo para inclusão das informações bancárias estará disponível após a validação dos documentos enviados na etapa de cadastro. Serão enviados os formulários de encaminhamento para abertura da conta bancária e exame médico.`,
	},
	{
		question: 'Não tenho PIS, devo continuar meu cadatro?',
		answer: `O cadastro deve ser continuado mesmo que o candidato não possua numero de PIS. Fique atento ao e-mail e aos ícones liberados após o preenchimento do pré cadastro.`,
	},
	{
		question:
			'Recebi uma mensagem para  ajustar dados incorretos. O que devo fazer para corrigi-las?',
		answer: `Entre no Portal de Boas - vindas e estará habilitado ícone que informará todas as pendências existentes. Efetue o acerto e salve para que ocorra uma nova análise dos dados corrigidos.`,
	},
	{
		question:
			'É possível consultar meus dados depois que finalizei meu cadastrado?',
		answer: `Não, uma vez que os dados foram validados pelo candidato essa etapa é finalizada. As interações serão sempre feitas via e-mail.`,
	},
	{
		question:
			'Tem como entregar meus documentos pessoalmente ou só pela plataforma?',
		answer: `Não há interação fisíca para esta etapa. Todas as interações para cadasatro, inclusive comprovantes que reflitam as informações inseridas no Portal serão validadas online.`,
	},
	{
		question: 'Por quanto tempo meus dados ficam armazenados na plataforma?',
		answer: `Seus dados ficarão no portal durante todo o processo de cadastro. Após a conclusão da admissão, os dados serão excluídos do Portal. Caso haja desistência ou reprovação do candidato, os dados serão excluídos automaticamente.`,
	},
	{
		question:
			'Não sou casado(a) no civil, devo colocar os dados do conjugê de união estável?',
		answer: `Se possuir a declaração de união estável registrado em cartório pode seguir como o cadastro do conjugê.`,
	},
	{
		question:
			'Meu comprovante de residência está com nome de terceiros, posso utiliza-lo?',
		answer: `Sim.`,
	},
	{
		question: 'Somente o banco Bradesco para abertura de conta salário?',
		answer: `Matemos convênio com o banco Bradesco, onde direcionamos para conta salário sua remuneração mensal. Existirá ainda a possibilidade de portabilidade junto ao banco`,
	},
	{
		question:
			'Não tenho a documentação necessária para efetuar o preenchimento do cadastro, como faço?',
		answer: `O candidato será informado sobre o prazo de preenchimento do cadastro. Até essa data será possivel providenciar os documentos pendentes para dar continuidade ao processo.`,
	},
	{
		question: 'Como saber em tal etapa do processo seletivo estou?',
		answer: `As etapas do processo estarão descritas na parte superior da tela do navegador`,
	},
]

const matchNSortFaqItems = (faqItems, query) => {
	const faqItemsQuestionsMatched = []
	const faqItemsMatched = []

	for (const item of faqItems) {
		const { matches: matchesOnlyQuestionNKeywords, regexQuery } = queryMatch({
			str: `${item.question} ${item.keywords ?? ''}`,
			query,
		})

		if (matchesOnlyQuestionNKeywords) faqItemsQuestionsMatched.push(item)
		else if (
			queryMatch({
				str: `${item.question} ${item.answer} ${item.keywords ?? ''}`,
				regexQuery,
			}).matches
		)
			faqItemsMatched.push(item)
	}

	return [...faqItemsQuestionsMatched, ...faqItemsMatched]
}

const FaqPage = () => {
	// pegando parâmetros da url
	const { search } = useLocation()
	const urlParams = queryString.parse(search)
	const hideMenu = Object.keys(urlParams).includes('ns')

	// estado do campo de pesquisa
	const [searchValue, setSearchValue] = useState('')

	const vp = useSelector(getViewPortSize)
	const { isDesktop } = breakpointMatch(vp)

	return (
		<MainLayout onGoBack={() => history.push(homePath)} hideMenu={hideMenu}>
			<FaqWrapper>
				{isDesktop && (
					<>
						<Title size="1.8rem">Perguntas Frequentes</Title>
						<Text>
							<p>
								O Portal Boas Vindas é o caminho por onde a Lojas Americanas vai
								promover toda a comunicação para a vaga que você está se
								candidatando.
							</p>
							<p>Pesquise abaixo para encontrar a resposta para sua dúvida.</p>
						</Text>
					</>
				)}
				<StyledDarkBox>
					<Title>Qual é a sua dúvida?</Title>

					<StyledTextInput
						placeholder="Utilize palavras-chave"
						value={searchValue}
						onChange={(e) => setSearchValue(e.target.value)}
						EndIcon={IconSearch}
						autoComplete="off"
					/>
				</StyledDarkBox>

				{matchNSortFaqItems(faq, searchValue).map(
					(faqItem, index, matchedFaqItems) => (
						<Accordion
							key={faqItem.question}
							label={faqItem.question}
							open={
								!!(searchValue && (index === 0 || matchedFaqItems.length < 4))
							}
						>
							{faqItem.answer}
						</Accordion>
					),
				)}
			</FaqWrapper>
		</MainLayout>
	)
}

export default FaqPage
