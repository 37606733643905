/* eslint-disable */
import { cloneDeep, get, isEmpty, update } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import {
	attachmentObjToUuid,
	attachmentUuidToObj,
	composeConverters,
	dateFieldsIsoToStr,
	dateFieldsStrToIso,
	monthFieldsIsoToStr,
	monthFieldsStrToIso,
} from '~/helpers/converters'
import handleReqError from '~/helpers/handleReqError'
import api from '~/services/api'
import { loadUser, logout } from '~/store/reducers/user/actions'
import { useCheckStageActive } from './useCheckStageActive'

const mergeWithDefault = (defaultValues) => (values) => {
	if (!defaultValues || isEmpty(defaultValues)) return values

	values = cloneDeep(values)
	for (const k of Object.keys(defaultValues)) {
		update(values, k, (value) => value ?? defaultValues[k])
	}
	return values
}

const usePBVForm = (
	formName,
	{
		defaultValues,
		attachmentFields,
		dateFields,
		monthFields,
		afterSubmit,
		isNew,
		stagePath,
	} = {},
) => {
	// hook para verificar se na etapa deste form (vindo de GET /etapas) a propriedade "ativo" está false ou true.
	// se ativo: false >>> editingDisabled = true
	// stagePath é usado para verificar na etapa relativa a outro path (caso da página FamilyMemberPage, que verifica com a rota /dados-cadastrais/familia)
	const { editingDisabled } = useCheckStageActive(stagePath)

	const interceptValues = useCallback(
		(values) => {
			return composeConverters([
				attachmentUuidToObj(attachmentFields),
				dateFieldsIsoToStr(dateFields),
				monthFieldsIsoToStr(monthFields),
				mergeWithDefault(defaultValues),
			])(values)
		},
		[attachmentFields, dateFields, defaultValues, monthFields],
	)

	const dispatch = useDispatch()

	const [loading, setLoading] = useState(!isNew)
	const [error, setError] = useState(undefined)
	const [initialValues, setInitialValues] = useState({})

	useEffect(() => {
		if (!isNew) {
			const cancelSource = api.getCancelSource()

				; (async () => {
					try {
						const { data } = await api.forms(formName).get({ cancelSource })
						// await delay(100000)\

						setInitialValues(interceptValues(data))

						// setError('erro')
					} catch (err) {
						console.error(`erro no GET do ${formName}`, err.response)
						setError(err.response)

						if (err.response.status === 404) {
							dispatch(logout())
						}
					} finally {
						setLoading(false)
					}
				})()

			return () => cancelSource.cancel()
		}

		setInitialValues(interceptValues({}))
	}, [formName, interceptValues, isNew])

	const submitForm = async (values) => {
		if (Array.isArray(attachmentFields)) {
			for (const f of attachmentFields) {
				const v = get(values, f)
				if (v && v?.file && !v?.uuid) {
					toast.loading(
						'Aguarde enquanto terminamos de subir seus arquivos e tente novamente...',
						{ id: f },
					)

					throw new Error('Subindo arquivos...') // interrompe envio do form
				}
			}
		}

		try {
			values = composeConverters([
				attachmentObjToUuid(attachmentFields),
				dateFieldsStrToIso(dateFields),
				monthFieldsStrToIso(monthFields),
			])(values)

			let result
			if (isNew)
				result = await api.forms(formName).post({ values })
			else
				result = await api.forms(formName).put({ values })

			console.log(result)
			// toast.success('Cadastro salvo!')
			toast.success(result.data.message || 'Cadastro salvo!')

			// recarregando usuário e etapas após envio do formulário (para atualizar porcentagem)
			dispatch(loadUser())

			if (typeof afterSubmit === 'function') afterSubmit(values, initialValues)
		} catch (err) {
			console.error(`erro no PUT do ${formName}`, err)
			handleReqError(err, {
				400: (msg) => toast.error(msg),
				default: (msg) => {
					// toast.error('Erro ao salvar o cadastro. Tente novamente')
					toast.error(msg)
				},
			})

			throw err
		}
	}

	return {
		loading,
		error,
		initialValues,
		submitForm,
		editingDisabled,
	}
}

export default usePBVForm
