/* eslint-disable */
import React, { useState } from 'react'
import setFieldData from 'final-form-set-field-data'
import { Field, Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'
// import queryString from 'query-string'
// import { useLocation } from 'react-router-dom'

import {
	Button,
	FieldWrapper,
	FormBody,
	FormFooter,
	FormWrapper,
	Loading,
	Text,
	FieldValuesSpy,
	CheckBoxField,
	FormSection,
	FamilyMember,
	Modal,
	FormTip,
	Link,
	FormInfo,
} from '~/components'
import history from '~/helpers/history'
import MainLayout from '~/layouts/mainLayout'
import { path as familyMemberPath } from '../familyMemberPage'
import usePBVForm from '~/hooks/usePBVForm'
import api from '~/services/api'
import { getUser } from '~/store/reducers/user/selectors'
// import { path as registrationPath } from '../registrationPage'
import { loadUser, setUser } from '~/store/reducers/user/actions'

import ErrorPage from '../errorPage'
import { Hr } from './styles'

export const title = 'Família'
export const path = '/dados-cadastrais/familia'

const FamilyPage = () => {
	// const { search } = useLocation()
	// const { backTo } = queryString.parse(search)

	const dispatch = useDispatch()

	const { loading, error, initialValues, submitForm, editingDisabled } =
		usePBVForm(`familia`)

	const user = useSelector(getUser)
	const partnerFilledOrInexistant =
		(user?.estadoCivil === 'casado' && user?.conjuge) ||
		(user?.estadoCivil !== undefined && user?.estadoCivil !== 'casado')

	const handleGoBack = () => history.push('/')

	const handleSubmit = async (values) => {
		try {
			if (!editingDisabled) {
				await submitForm(values)
			} else {
				handleGoBack()
			}
		} catch (err) {
			console.log(err)
		}
	}

	const handleMemberEdit = (uuid) => () => {
		history.push(familyMemberPath.replace(':id', uuid))
	}

	const [loadingConfirmation, setLoadingConfirmation] = useState(false)
	const [confirmation, setConfirmation] = useState(null)

	const handleMemberDelete = (member, options) => () => {
		const onConfirm = async () => {
			setLoadingConfirmation(true)

			try {
				let result = await api.familia.delete({ uuid: member.uuid })

				options?.callback && options.callback()
				setConfirmation(null)
				toast.success(result.data.message || `Familiar excluído`)
				setLoadingConfirmation(false)
				dispatch(loadUser())
			} catch (err) {
				console.error(err)
				setLoadingConfirmation(false)
				toast.error('Erro ao excluir familiar. Tente novamente')
			}
		}

		setConfirmation({ ...member, onConfirm })
	}

	if (loading)
		return (
			<MainLayout hasSideMenu onGoBack={handleGoBack}>
				<Loading />
			</MainLayout>
		)
	if (error) return <ErrorPage hasSideMenu onGoBack={handleGoBack} />

	return (
		<MainLayout hasSideMenu onGoBack={handleGoBack}>
			<Form
				onSubmit={handleSubmit}
				initialValues={initialValues}
				subscription={{
					submitting: true,
					submitSucceeded: true,
					pristine: true,
					submitError: true,
				}}
				mutators={{ setFieldData }}
				validate={() => ({})}
			>
				{({ handleSubmit, submitting }) => (
					<FormWrapper onSubmit={handleSubmit}>
						<FormBody>
							<Text dark>
								{editingDisabled ? (
									<FormInfo>
										Você já enviou seus dados de família e neste momento do
										processo eles não podem ser alterados
									</FormInfo>
								) : (
									<p>
										Preencha seus dados com muita atenção e não deixe de anexar
										a foto do documento oficial que comprove os dados
										preenchidos.
									</p>
								)}
							</Text>

							<Field name="docs">
								{({ input: { value, onChange } }) =>
									value?.length ? (
										// listando familiares
										value.map((f) => (
											<FieldWrapper key={f?.uuid}>
												<FamilyMember
													name={f?.nome}
													type={f?.tipoFamiliar}
													percent={f?.porcentagem}
													onEdit={handleMemberEdit(f?.uuid)}
													onDelete={handleMemberDelete(f, {
														callback: () => {
															// chama 'onChange' do Field do final-form para filtrar listagem retirando membro clicado
															onChange(
																value.filter((vf) => vf.uuid !== f?.uuid),
															)
															if (f?.tipoFamiliar === 'conjuge')
																dispatch(setUser({ conjuge: undefined }))
														},
													})}
													disabled={editingDisabled}
												/>
											</FieldWrapper>
										))
									) : (
										// não há familiares para listar
										<FormSection label="Dados de familiares">
											{user?.estadoCivil !== 'casado' ? (
												<FieldWrapper>
													<CheckBoxField
														name="flagNaoPossuoFamilia"
														label="Não possuo familiares a declarar"
														disabled={editingDisabled}
													/>
												</FieldWrapper>
											) : (
												<FormTip>
													Na etapa{' '}
													<Link to="/dados-cadastrais/dados-pessoais">
														Dados Pessoais
													</Link>{' '}
													você informou ser casado, portanto aqui é necessário
													inserir seu cônjuge como familiar
												</FormTip>
											)}

											<Hr />

											<FieldValuesSpy name="flagNaoPossuoFamilia">
												{(flagNaoPossuoFamilia) =>
													!editingDisabled &&
													!flagNaoPossuoFamilia && (
														<Button
															large
															to={familyMemberPath.replace(':id', 'novo')}
														>
															Adicionar{' '}
															{partnerFilledOrInexistant ? 'filho' : 'familiar'}
														</Button>
													)
												}
											</FieldValuesSpy>
										</FormSection>
									)
								}
							</Field>
						</FormBody>

						<FormFooter>
							{!editingDisabled && (
								<FieldValuesSpy names={['flagNaoPossuoFamilia', 'docs']}>
									{({ flagNaoPossuoFamilia, docs }) =>
										!flagNaoPossuoFamilia && docs?.length ? (
											<>
												{user?.estadoCivil === 'casado' && !user?.conjuge && (
													<FormTip style={{ marginBottom: 'var(--spacing)' }}>
														Na etapa{' '}
														<Link to="/dados-cadastrais/dados-pessoais">
															Dados Pessoais
														</Link>{' '}
														você informou ser casado, portanto aqui é necessário
														inserir seu cônjuge como familiar
													</FormTip>
												)}
												<Button
													large
													to={familyMemberPath.replace(':id', 'novo')}
												>
													Adicionar{' '}
													{partnerFilledOrInexistant ? 'filho' : 'familiar'}
												</Button>
											</>
										) : undefined
									}
								</FieldValuesSpy>
							)}

							<Button type="submit" primary large submitting={submitting}>
								{editingDisabled ? 'Voltar' : 'Salvar'}
							</Button>
						</FormFooter>
					</FormWrapper>
				)}
			</Form>

			{/* confirmação de exclusão do familiar */}
			<Modal
				isOpen={Boolean(confirmation)}
				onRequestClose={() => setConfirmation(null)}
				renderHeader={() => <>Deseja excluir {confirmation?.nome}?</>}
				renderFooter={() => (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-evenly',
							width: '100%',
						}}
					>
						<Button
							style={{ width: '5em' }}
							onClick={() => setConfirmation(null)}
						>
							Não
						</Button>
						<Button
							style={{ width: '5em' }}
							disabled={loadingConfirmation}
							primary
							onClick={confirmation?.onConfirm}
						>
							Sim
						</Button>
					</div>
				)}
			/>
		</MainLayout>
	)
}

export default FamilyPage
