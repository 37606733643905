import React from 'react'
import styled from 'styled-components'
import { mq } from '~/helpers/breakpoints'
import LogoAmericanas from './logoAmericanas'

function LogoBancoTalentos(props) {
	return (
		<Wrapper {...props}>
			<LogoAmericanas color="var(--color-red)" size={1.15} />
			<Title>Portal Boas Vindas</Title>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	font-size: 1rem;

	${mq.min('md')} {
		font-size: 1.8rem;
		margin-bottom: 0.5em;
	}
`
const Title = styled.h1`
	font-size: 1em;
	font-weight: 500;
	margin: 0;
	margin-left: 0.4em;
	color: var(--color-text-dark);

	${mq.min('md')} {
		font-size: 1.05em;
		margin-left: 0.7em;
	}
`

export default LogoBancoTalentos
